<template>
    <div class="contain" v-infinite-scroll="load" :infinite-scroll-disabled="finish">
        <searchBar :searchInput="false"></searchBar>
        <div class="bgf mb_10">
            <div class="title bottom_link flex">
                <div class="fs_18 color_333 flex_start">
                    <span>{{ detail.reward_name }}</span>
                </div>
            </div>
            <div class="bottom fs_14">
                <el-row>
                    <el-col :span="7">
                        <span class="label sub_color">任务编号：</span>
                        <span class="color_333">{{
							detail.reward_no
						}}</span>
                    </el-col>
                    <el-col :span="6">
                        <span class="label sub_color">计价方式：</span>
                        <span class="color_red fs_16">{{
							detail.settle_info
						}}</span>
                    </el-col>
                    <el-col :span="6">
                        <span class="label sub_color">结算周期：</span>
                        <span class="color_333">{{
							detail.price_type
						}}</span>
                    </el-col>
                    <el-col :span="5">
                        <span class="label sub_color">结算形式：</span>
                        <span class="color_333">{{
							detail.settle_type
						}}</span>
                    </el-col>

                </el-row>
                <el-row class="mt_10">
                    <el-col :span="7">
                        <span class="label sub_color">接单主体：</span>
                        <span class="color_333">{{ detail.role_limit_text }}
                        </span>
                    </el-col>
                    <el-col :span="6">
                        <span class="label sub_color">联系人：</span>
                        <span class="color_333">{{
							detail.publish_contacts
						}}</span>
                    </el-col>
                    <el-col :span="6">
                        <span class="label sub_color">联系方式：</span>
                        <span class="color_333">{{
							detail.publish_tel
						}}</span>
                    </el-col>
                    <el-col :span="5">
                        <span class="label sub_color">发布人：</span>
                        <span class="color_333">{{
							detail.publish_user_name
						}}</span>
                    </el-col>
                </el-row>
                <el-row class="mt_10">
                    <el-col :span="7">
                        <span class="label sub_color">起止时间：</span>
                        <span class="color_333">{{
							detail.publish_date
						}}</span>
                    </el-col>
                    <el-col :span="6">
                        <span class="label sub_color">任务状态：</span>
                        <span class="color_333">{{ userInfo.status }}</span>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="bgf">
            <div class="list">
                <div class="flex top bottom_link">
                    <div class="flex_c">
                        <el-avatar :src="userInfo.avatar" :size="60" class="mr_20 avatar" alt="头像"></el-avatar>
                        <div>
                            <div class="flex_c">
                                <p class="fs_20 bold mr_15">{{ userInfo.realname }}</p>
                                <p class="fs_14 bold">{{ userInfo.mobile }}</p>
                            </div>
                            <div class="flex mt_5">
                                <div class="use_tip fs_14 color_333">
                                    <!-- <span class="line">市场部</span> -->
                                    <span class="line">{{ userInfo.create_time }} 申请</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex_c">
                        <div class="btn fs_14 center color_999" v-if="userInfo.status=='进行中'" @click="finishReward">结 束</div>
                        <template v-if="status==1">
                            <div class="btn fs_14 center color check" @click="payRecord(id)">添加支出</div>
                            <router-link :to="`/employment/pay-list/${id}/${reward_id}/${status}`" class="btn fs_14 center color check">支出记录</router-link>
                        </template>
                    </div>
                </div>

                <div class="item fs_14 bottom_link" v-for="(item, index) in list" :key="index">
                    <p class="flex_start">
                        <span class="label sub_color">记录日期：</span>
                        <span class="color_666 flex_1 mt_3">{{ item.create_time }}</span>
                    </p>
                    <p class="flex_start mt_10">
                        <span class="label sub_color">位&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;置：</span>
                        <span class="color_666 flex_1">{{ item.address }}</span>
                    </p>
                    <p class="flex_start mt_10">
                        <span class="label sub_color">经营日志：</span>
                        <span class="color_666 flex_1">{{ item.content }}</span>
                    </p>
                    <div class="flex_start mt_10">
                        <span class="label sub_color">经营凭证：</span>
                        <div>
                            <el-image class="imgs" v-for="img in item.upload_img" :src="img" :key="img" :preview-src-list="item.upload_img">
                            </el-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <empty text="暂无经营日志" :finish="finish" :length="list.length" v-if="list.length == 0"></empty>

        <pay-msg :id="id" ref="paymsg"></pay-msg>

    </div>
</template>

<script>
import payMsg from "./dialog/pay-msg"
import { s_getItem, s_removeItem } from "@/common/cache"
export default {
	name: "EmploymentRunRecord",
	components: { payMsg },
	data() {
		return {
			dialogVisible: false,
			reward_id: "",
			id: "",
			status: 1,
			list: [],
			page: 1,
			limit: 10,
			finish: false,
			detail: {},
			userInfo: {}
		}
	},
	created() {
		this.detail = s_getItem('_taskDetail')
		this.userInfo = s_getItem('_taskUserInfo')
		// this.userInfo.realname = this.userInfo.realname.substring(0, 1)+'**'
		// this.userInfo.mobile = this.userInfo.mobile.substring(0, 3)+'****'+this.userInfo.mobile.substring(7, 11)
		this.reward_id = this.$route.params.reward_id
		this.id = this.$route.params.id
		this.status = this.$route.params.status
		this.getList()
	},
	beforeRouteLeave (to, from, next) {
		if(to.name !== 'EmploymentPayList') {
			s_removeItem('_taskUserInfo')
		}
		next()
	},

	methods: {
		load() {
			this.page++
			this.getList()
		},
		getList() {
			this.$axios({
				url: "/ent/v3/apply/record",
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					apply_id: this.id
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = this.list.concat(res.data.list)
					this.finish = this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		finishReward() {
			this.$confirm(`是否结束 <span class="color_000 bold">${this.detail.reward_name}</span> 任务?`, '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			}).then(() => {
				this.$axios({
					url: `/ent/v3/apply/finish/${this.id}`,
					method: 'POST',
					shake: true
				}).then(res => {
					if (res.code === 200) {
						this.userInfo.status = '已结束'
						this.$message.success(`${this.detail.reward_name}任务已结束`)
					}
				})
			})
		},
		payRecord(id) {
			this.id = id
			this.$refs.paymsg.dialogVisible = true
		}
	}
}
</script>
<style lang="less" scoped>
@import "./style/list.less";
.loading {
    padding-top: 30px;
}

.title {
    padding: 25px 30px 20px 30px;
    border-radius: 2px;
}
.bottom {
    padding: 20px 30px;
    .label {
        width: 100px;
        display: inline-block;
    }
}
.list {
    .item {
        padding: 20px 30px;
        // height: 130px;
        .label {
            width: 78px;
        }
        .btn {
            width: 128px;
            height: 30px;
            background: #ffffff;
            border: 1px solid #e3e3e3;
            border-radius: 15px;
            color: #999999;
            margin: 0 auto;
            &.in {
                color: #1890ff;
                border-color: #1890ff;
            }
        }
        /deep/.el-image-viewer__canvas {
            img {
                width: auto;
            }
        }
        .imgs {
            width: 100px;
            height: 100px;
            border-radius: 2px;
            margin: 0 10px 10px 0;
        }
    }
}
</style>
