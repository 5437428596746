<template>
    <div class="ruleDialog">
        <el-dialog v-model="dialogVisible" :lock-scroll="true" width="492px" top="20vh" destroy-on-close :before-close="close">
            <template v-slot:title>
                <div class="rule-tab">
                    <p class="fs_18 bold item btn">添加支出</p>
                </div>
            </template>
            <div class="content">
                <el-form :label-position="top" :model="form" :rules="rules" ref="ruleForm" class="form" hide-required-asterisk>
                    <div class="flex_start">
                        <el-form-item prop="price" class="flex_1">
                            <template v-slot:label>
                                <div>
                                    <span style="color:#F56C6C">*</span>本次新增支出<span class="fs_12 color_999">（接单人将以此金额申请开票）</span>
                                </div>
                            </template>
                            <el-input v-model.trim="form.price" placeholder="请输入打款金额" :readonly="type>0" type="number"><template #append>元</template></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex_start">
                        <el-form-item>
                            <template v-slot:label>
                                <div>
                                    付款凭证<span class="fs_12 color_999">（选填，后续可补充上传）</span>
                                </div>
                            </template>
                            <el-upload :action="`${baseApi}/api/v2/upload/image?filename=file&type=company`" :headers="headers" accept=".jpg,.jpeg,.png" :show-file-list="false" :on-success="handleLogoSuccess" :before-upload="beforeLogoUpload" :on-progress="handleLogoProgress">
                                <div v-if="form.voucher">
                                    <img :src="form.voucher">
                                </div>
                                <div class="flex_cen" v-else>
                                    <img src="@/assets/imgs/icon/img-icon.png" class="img-icon" v-if="avatarProgress==0">
                                    <el-progress type="circle" :percentage="avatarProgress" width="90" class="mt_5" v-else></el-progress>
                                </div>
                            </el-upload>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <template v-slot:footer>
                <div class="flex_around">
                    <el-button type="primary" class="pay-msg-button" :disabled="type>0&&!form.voucher" @click="submitForm('ruleForm')">
                        {{ type>0?'保 存':'确认并保存支出记录' }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { getToken, removeToken } from '@/common/cache'
export default {
	name: "",
	data() {
		const validateNumber = (rule, value, callback) => {
			if (value <= 0) {
				callback(new Error("金额不能小于0"))
			} else {
				callback()
			}
		}
		return {
			baseApi: process.env.VUE_APP_API,
			headers: {
				'Authorization': 'Bearer ' + getToken()
			},
			avatar_tmp: '',
			avatarProgress: 0, // 头像 上传进度
			form: {
				price: null,
				voucher: ''
			},
			rules: {
				price: [
					{ required: true, message: "请输入打款金额", trigger: "blur" },
					{ validator: validateNumber, trigger: "blur" }
				]
			},
			dialogVisible: false
		}
	},
	props: {
		id: {
			type: Number,
			default: 0
		},
		type: {
			type: Number,
			default: 0
		}
	},
	methods: {
		close() {
			this.dialogVisible = false
			this.form.voucher = ''
		},
		handleLogoSuccess(res, file) {
			if (res.code !== 200) {
				if (res.code === 410000) {
					this.$message.error('请登录')
					removeToken()
					this.$router.push('/login')
				} else {
					this.$message.error(res.msg)
				}
			} else {
				this.avatar_tmp = URL.createObjectURL(file.raw)
				this.form.voucher = res.data.url
				this.avatarProgress = 0
			}
		},
		beforeLogoUpload(file) {
			this.avatar_tmp = ''
			this.form.voucher = ''
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
			const isLt2M = file.size / 1024 / 1024 < 5
			if (!isJPG) {
				this.$message.error('上传凭证图片只能是 JPG 或 PNG 格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传凭证图片大小不能超过 5MB!')
			}
			return isJPG && isLt2M
		},
		handleLogoProgress(event) {
			this.avatarProgress = Math.floor(event.percent)
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					let data = {
						total_amount: this.form.price,
						upload_image: this.form.voucher
					}
					if(this.type==0) {
						data.apply_id = this.id
					}
					this.$axios({
						url: `/ent/v3/income${this.type>0?'/'+this.id:''}`,
						method: this.type>0?'PUT':'POST',
						data,
						shake: true
					}).then(res => {
						if (res.code === 200) {
							this.$message.success("添加成功")
							this.$emit('init')
							// if(this.type==0) {
							// 	this.$parent.$parent.init()
							// }else {
							//     this.$parent.init()
							// }
							this.close()
							this.form.price = ''
						}
					})
				} else {
					console.log("error submit!!")
					return false
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.flex_end {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.ruleDialog {
    /deep/.el-dialog__header {
        padding: 0;
    }
    /deep/.el-dialog__body {
        padding: 0;
    }
    .rule-tab {
        padding: 0 20px;
        .item {
            margin-right: 20px;
            line-height: 54px;
            border-bottom: 2px solid #fff;
            &.in {
                color: #1890ff;
                border-color: #1890ff;
            }
        }
    }
    .content {
        border-top: 1px solid #ddd;
        padding: 40px 50px 20px;
    }
    .pay-msg-button {
        width: 176px;
        font-size: 16px;
        height: 46px;
        margin-bottom: 30px;
    }
    /deep/.form {
        .el-form-item__label {
            padding: 0 !important;
        }
        .el-input__inner {
            height: 44px;
            background-color: #f9f9f9;
            border-radius: 0px;
            width: 100% !important;
        }

        .el-input {
            .el-input__count {
                .el-input__count-inner {
                    background-color: transparent;
                }
            }
        }
        .price {
            width: 40px;
            height: 108px;
            line-height: 108px;
            text-align: right;
        }
        .el-upload {
            width: 100px;
            height: 100px;
            border-radius: 16px;
            border: 1px dashed #e3e3e3;
            background-color: #f9f9f9;
            overflow: hidden;
            position: relative;
            .tip-text {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 3;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.3);
                display: none;
            }
            .file-img {
                width: auto;
                &:hover {
                    .tip-text {
                        display: flex;
                    }
                }
            }
        }
        .img-icon {
            width: 20px;
            height: 20px;
        }
        .el-form-item__label {
            font-size: 16px;
            padding: 0 !important;
        }
    }
    /deep/.el-form-item__label {
        font-size: 16px;
    }
    /deep/.el-upload-list .el-upload-list__item,
    /deep/.el-upload {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /deep/.el-input-group__append {
        background: #f9f9f9;
    }
}
</style>
